import React, { FunctionComponent, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { SelectField } from 'components/elements/SelectField'
import { useTranslation } from 'react-i18next'
import { useApi } from 'components/providers/ApiProvider'
import { useMessage } from 'components/elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import { useSubscriptions } from 'components/views/subscriptions/subscriptions/SubscriptionsProvider'
import { TextField } from 'components/elements/TextField'
import { DateField } from 'components/elements/DateField'
import { DurationType } from 'models/ZoneOfferModel'
import { addDays, addMonths, format, subMinutes } from 'date-fns'
import { formatDateFieldToISO, isDateFieldValid, parseDateField, yupDateField } from 'utils'
import MuiTextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const EndDateField = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
}))(MuiTextField)

type Props = {
  open: boolean
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const AddSubscriptionDialog: FunctionComponent<AllProps> = ({ open, setOpen }) => {
  const { t, i18n } = useTranslation()
  const api = useApi()
  const { addZones: zones, setLoadList } = useSubscriptions()
  const { showSuccess, showError } = useMessage()

  const [offerOptions, setOfferOptions] = useState([])
  const [endTime, setEndTime] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (zones.length == 1) {
      formal.change('zone', zones[0].id)
    }
  }, [zones])

  const initialValues = {
    zone: 0,
    offer: 0,
    start: format(new Date, 'dd.MM.yyyy'),
    till: '',
    count: 1,
    single: false,
    plateNumbers: '',
    client: '',
    notes: '',
  }

  const schema = yup.object().shape({
    zone: yup.number().required().min(1),
    offer: yup.number().required().min(1),
    start: yupDateField({ required: true }),
    till: yupDateField(),
    count: yup.number().required().integer().min(0).max(100),
    single: yup.boolean(),
    plateNumbers: yup.string().matches(/^[0-9a-zA-Z, \n]*$/),
    client: yup.string().trim().matches(/^[0-9a-zA-Z]{1,30}$/, { excludeEmptyString: true }),
    notes: yup.string().trim(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: async values => {
      values = schema.cast(values)

      await api.createSubscription({
        offer_id: values.offer,
        start: formatDateFieldToISO(values.start),
        till: values.till ? formatDateFieldToISO(values.till) : undefined,
        count: values.count,
        single: values.single || undefined,
        ...(!values.single && values.plateNumbers && {
          plate_numbers: values.plateNumbers.split(/[,\n]/).map(plateNumber => plateNumber.replace(/\s/g, '').toUpperCase()).filter((plateNumber, index, self) => plateNumber && self.indexOf(plateNumber) === index),
        }),
        client: values.client || undefined,
        notes: (!values.single && values.notes) || undefined,
      })
        .then(response => {
          if (!response || !response.status) {
            throw new Error
          }

          handleClose()

          if (zones.length > 1) {
            formal.change('zone', initialValues.zone)
          } else if (offerOptions.length > 1) {
            formal.change('offer', initialValues.offer)
          } else {
            handleStartDateChange(initialValues.start)
          }
          formal.change('till', initialValues.till)
          formal.change('count', initialValues.count)
          formal.change('single', initialValues.single)
          formal.change('plateNumbers', initialValues.plateNumbers)
          formal.change('client', initialValues.client)
          formal.change('notes', initialValues.notes)

          setLoadList()

          showSuccess(t('messages.subscriptionAssigned'))
        })
        .catch(error => {
          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            case 6:
              message = t('alerts.maxSubscriptionsReached')
              break
            case 9:
              message = t('alerts.maxSubscriptionVehiclesReached')
              break
            case 10:
              message = t('subscriptionPurchase.alertSubscriptionUpdateFailedVehicleOverlapBody', { plates: error.extra.plates.join(', ') })
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotAssignSubscription')} ${message}`)
        })
    },
  })

  useEffect(() => {
    const offerOptions = ((formal.values.zone && (zones.find(z => z.id == formal.values.zone).subscription_offers)) || []).map(offer => ({
      label: offer.name,
      id: offer.id,
    }))

    setOfferOptions(offerOptions)

    formal.change('offer', offerOptions.length == 1 ? offerOptions[0].id : 0)
  }, [formal.values.zone])

  useEffect(() => {
    const offer = formal.values.zone && formal.values.offer && zones.find(z => z.id == formal.values.zone).subscription_offers.find(o => o.id == formal.values.offer)

    if (offer && offer.duration_type == DurationType.monthsAbsolute) {
      handleStartDateChange(format(new Date, '01.MM.yyyy'))
    } else {
      handleStartDateChange(initialValues.start)
    }

    formal.change('till', initialValues.till)
  }, [formal.values.offer])

  const handleStartDateChange = (value) => {
    formal.change('start', value)

    if (!value || !isDateFieldValid(value)) {
      setEndTime('')

      return
    }

    const offer = formal.values.zone && formal.values.offer && zones.find(z => z.id == formal.values.zone).subscription_offers.find(o => o.id == formal.values.offer)

    if (!offer) {
      setEndTime('')

      return
    }

    switch (offer.duration_type) {
      case DurationType.infinity:
        setEndTime('...')
        break

      case DurationType.daysAbsolute:
        setEndTime(format(subMinutes(addDays(parseDateField(value), offer.duration_length), 1), 'dd.MM.yyyy HH:mm'))
        break

      case DurationType.daysRelative:
        setEndTime('...')
        break

      case DurationType.monthsAbsolute:
        setEndTime(format(subMinutes(addMonths(parseDateField(value), offer.duration_length), 1), 'dd.MM.yyyy HH:mm'))
        break

      case DurationType.monthsRelative:
        setEndTime(format(subMinutes(addMonths(parseDateField(value), offer.duration_length), 1), 'dd.MM.yyyy HH:mm'))
        break

      case DurationType.hours:
        setEndTime('...')
        break
    }
  }

  return (
    <DraggableDialog fullWidth open={open} onClose={handleClose}>
      <DraggableDialogTitle>{t('newSubscription')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container spacing={2}>
            <SelectField
              GridProps={{ xs: 12 }}
              label={t('zone')}
              required
              optionsCustom={[
                {
                  label: zones.length > 1 ? t('select') : '',
                  id: 0,
                },
                ...zones.sort((a, b) => a.code.localeCompare(b.code)).map(zone => {
                  const localization = zone.localizations.find(l => l.language == i18n.language)

                  return {
                    label: `${zone.code}${localization && localization.name ? ` ${localization.name}` : ''}`,
                    id: zone.id,
                  }
                }),
              ]}
              {...formal.getFieldProps('zone')}
              disabled={zones.length <= 1 || formal.isSubmitting}
            />
            <SelectField
              GridProps={{ xs: 12 }}
              label={t('type')}
              required
              optionsCustom={[
                {
                  label: offerOptions.length > 1 ? t('select') : '...',
                  id: 0,
                },
                ...offerOptions,
              ]}
              {...formal.getFieldProps('offer')}
              disabled={offerOptions.length <= 1 || formal.isSubmitting}
            />
            <DateField
              GridProps={{ xs: 6 }}
              label={t('startDate')}
              required
              {...(formal.values.zone && formal.values.offer && (zones.find(z => z.id == formal.values.zone).subscription_offers.find(o => o.id == formal.values.offer) || {}).duration_type === DurationType.monthsAbsolute && {
                format: '01.MM.yyyy',
                views: ['year', 'month'],
                openTo: 'month',
              })}
              {...formal.getFieldProps('start')}
              onChange={e => handleStartDateChange(e.target.value)}
            />
            {(formal.values.zone && formal.values.offer && (zones.find(z => z.id == formal.values.zone).subscription_offers.find(o => o.id == formal.values.offer) || {}).duration_type === DurationType.infinity) ? (
              <DateField
                GridProps={{ xs: 6 }}
                label={t('endDate')}
                {...(formal.values.start && { minDate: parseDateField(formal.values.start) })}
                clearable
                {...formal.getFieldProps('till')}
              />
            ) : (
              <Grid item xs={6}>
                <EndDateField
                  label={t('endTime')}
                  value={endTime}
                  fullWidth
                  variant="outlined"
                  disabled={true}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            )}
            <TextField
              GridProps={{ xs: 12 }}
              type="number"
              label={t('quantity')}
              required
              inputProps={{
                min: 0,
                max: 100,
              }}
              {...formal.getFieldProps('count')}
            />
            <Grid item xs={12} style={{ paddingLeft: 19 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...formal.getFieldProps('single')}
                    checked={formal.values.single}
                    color="primary"
                    onChange={(event) => formal.change('single', event.target.checked)}
                  />
                }
                label="Vienvietīgi"
              />
            </Grid>
            {!formal.values.single && <TextField
              GridProps={{ xs: 12 }}
              label={t('plateNumbers')}
              multiline
              autoComplete="off"
              helperText={`${t('example')}: AA0001, AA0002`}
              inputProps={{
                style: {
                  fontFamily: '"Roboto Mono", monospace',
                  lineHeight: '21px',
                  textTransform: 'uppercase',
                  marginTop: -1,
                  marginBottom: -1,
                  maxHeight: 63,
                  overflow: 'auto',
                },
              }}
              {...formal.getFieldProps('plateNumbers')}
            />}
            <TextField
              GridProps={{ xs: 12 }}
              label={t('client')}
              helperText={t('clientPhoneNumberOrUsernameHelperText')}
              {...formal.getFieldProps('client')}
            />
            {!formal.values.single && <TextField
              GridProps={{ xs: 12 }}
              label={t('notes')}
              {...formal.getFieldProps('notes')}
            />}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formal.isSubmitting}
          >
            {t('assign')}
          </Button>
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default AddSubscriptionDialog
