import React from 'react'
import { useConfig } from './ConfigProvider'
import { ActiveSubscription } from 'models/ActiveSubscription'
import { ZoneOfferModel } from 'models/ZoneOfferModel'
import { Car } from 'models/Car'

/* eslint-disable camelcase */

export interface IPermissionsData {
  permission: {
    name: string
    allowed: boolean
  }[]
}

export enum ParkingLotListEventType {
  Entry = 'entry',
  Exit = 'exit',
}

export interface IParkingLotListEventData {
  id: number
  zone_id: number | null
  camera_name: string
  type: ParkingLotListEventType
  occurred_at: string
  plate_number: string
  plate_number_original: string | null
  plate_number_image_url: string | null
  image_url: string | null
  parking_session_id?: number
  parking_session_exit_at?: string | null
  amount_unpaid?: number
  amount_paid?: number
  amount_additional_unpaid?: number
  amount_additional_paid?: number
  parking_session_has_special_rate?: boolean
  parking_session_special_rate_offer_id?: number
  parking_session_special_rate_name?: string
  parking_session_special_rate_assigned_user_name?: string
  parking_session_special_rate_assigned_at?: string
  autopay?: boolean
  exit_camera_name?: string | null
  exit_camera_event_id?: number | null
  exit_occurred_at?: string | null
  exit_plate_number_original?: string | null
  exit_image_url?: string | null
  subscription?: boolean
  permit?: boolean
  archived?: boolean
}

export interface IParkingLotListZoneData {
  id: number
  code: string
  localizations: {
    language: string
    name: string
  }[]
}

export interface IParkingLotAddZoneData {
  id: number
  code: string
  localizations: {
    language: string
    name: string
  }[]
  cameras: {
    id: number
    code: string
    type: 'entry' | 'exit' | 'bidi'
  }[]
}

export interface IParkingLotSessionSpecialRatesRateData {
  id: number
  name: string
  copayer_phone_number: string | null
  assigners: string | null
}

export enum ParkingLotSessionPaymentsPaymentOption {
  MobillyAutomatic = 'mobilly_automatic',
  MobillyStartStop = 'mobilly_startstop',
  MobillyMPay = 'mobilly_mpay',
  MobillyPostpay = 'mobilly_postpay',
  CashRegister = 'cash_register',
}

export interface IParkingLotSessionPaymentsPaymentData {
  id: number
  option: ParkingLotSessionPaymentsPaymentOption | null
  amount: number
  paid_at: string
  amount_refunded: number
  entry_at?: string
  exit_at?: string
  modify?: {
    refundable: boolean
    detachable: boolean
  }
}

export interface IParkingLotSessionAttachablePaymentsPaymentData {
  id: number
  amount: number
  paid_at: string
  entry_at: string
  exit_at: string
}

export interface IParkingLotStopEventsEventData {
  id: number
  occurred_at: string
  image_url: string | null
}

export interface IParkingPaymentsListZoneData {
  id: number
  code: string
  localizations: {
    language: string
    name: string
  }[]
}

export interface IParkingPaymentsListData {
  id: number
  zone_id: number
  zone: string
  amount: number
  paid_at: string
  option: ParkingLotSessionPaymentsPaymentOption | null
  amount_refunded: number
  external_payment_id?: number
  plate_number?: string
  entry_at?: string
  exit_at?: string
  session_id?: number
  session_plate_number?: string
  session_entry_at?: string
  session_exit_at?: string
  modify?: boolean
}


export interface ISubscriptionsListData {
  id: number
  purchased_at: string
  zone: string
  plate_numbers: string[]
  valid_from: string
  valid_till: string
  subscription_type: string
  auto_renew: boolean
  clients: string[]
  openid_clients: string[]
  count: number
  price: number
  notes: string
  modify?: {
    updatable: string[]
    stoppable: boolean
    deletable: boolean
  }
}

export interface ISubscriptionsListZoneData {
  id: number
  code: string
  localizations: {
    language: string
    name: string
  }[]
  subscription_types: {
    id: number
    name: string
  }[]
}

export interface ISubscriptionsAddZoneData {
  id: number
  code: string
  localizations: {
    language: string
    name: string
  }[]
  subscription_offers: {
    id: number
    name: string
    duration_type: string
    duration_length: number
  }[]
}

export interface IPermitsListData {
  id: number
  assigned_at: string
  zone: string
  plate_numbers: string[]
  valid_from: string
  valid_till: string
  clients: string[]
  count: number
  notes: string
  modify?: {
    updatable: string[]
    stoppable: boolean
    deletable: boolean
  }
}

export interface IPermitsListZoneData {
  id: number
  code: string
  localizations: {
    language: string
    name: string
  }[]
}

export interface IPermitsAddZoneData {
  zone_group_id: number
  zone_code: string
  zone_localizations: {
    language: string
    name: string
  }[]
}

export interface ISpecialRatesListRateData {
  id: number
  zone: string
  rate_name: string
  entry_time: string
  plate_number: string
  assigned_user_name?: string
}

export interface ISpecialRatesListData {
  sessions: ISpecialRatesListRateData[]
}

export interface ISpecialRatesZonesZoneData {
  id: number
  code: string | null
  localizations: {
    language: string
    name: string
    description: string
  }[]
  offers: {
    id: number
    name: string
  }[]
}

export interface ISpecialRatesZonesData {
  zones: ISpecialRatesZonesZoneData[]
}

export interface ISpecialRatesPlateNumbersData {
  plate_numbers: string[]
}

/* eslint-enable camelcase */

export class Api {
  private readonly url: string;
  private readonly myMobillyUrl: string;
  private readonly mpayAdapter: string;
  private readonly oauthMobillyClientId: string;

  constructor(url: string, myMobillyUrl: string, mpayAdapter: string, oauthMobillyClientId: string) {
    this.url = url
    this.myMobillyUrl = myMobillyUrl
    this.mpayAdapter = mpayAdapter
    this.oauthMobillyClientId = oauthMobillyClientId;
  }

  private async refreshToken(): Promise<boolean> {
    const refreshCode = localStorage.getItem('auth.refreshCode');
    if ( !refreshCode ) {
      return false;
    }

    const response = await fetch(this.url + '/v1/user/authenticate', {
      method: 'POST',
      body: JSON.stringify({
        token_type: 'code',
        token: refreshCode,
        response_type: 'full'
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    localStorage.removeItem('auth.refreshCode');

    if ( !response.ok ) {
      return false;
    }

    const data = await response.json();
    localStorage.setItem('auth.token', data.token);
    localStorage.setItem('auth.refreshCode', data.refreshCode);

    return true;
  }

  private async request(url: string, data?: any, authorize: boolean = true, accept?: string, init?: RequestInit): Promise<Response> {

    const realInit: RequestInit = {
      ...init,
      method: "POST",
    };
    realInit.headers = {
      ...realInit.headers,
    };

    if ( data ) {
      realInit.body = JSON.stringify(data);
      realInit.headers['Content-Type'] = 'application/json';
    }
    if ( accept ) {
      realInit.headers['Accept'] = accept;
    }

    if ( authorize ) {
      const token = localStorage.getItem('auth.token')

      if (!token && !(await this.refreshToken())) {
        throw {
          status: 401,
          message: 'API token does not exist',
        };
      }
      realInit.headers['Authorization'] = `Bearer ${token}`;
    }

    let response = await fetch(url, realInit);

    if ( response.status === 401 && authorize ) {
      localStorage.removeItem('auth.token');
      if ( await this.refreshToken() ) {
        const token = localStorage.getItem('auth.token');
        realInit.headers['Authorization'] = `Bearer ${token}`;
        response = await fetch(url, realInit);
      }
    }

    if (!response.ok) {
      let json: any;
      try {
        json = await response.json();
      } catch {
        throw {status: response.status};
      }

      throw {
        status: response.status,
        code: json.code,
        message: json.message,
        extra: json.extra,
      }
    }

    return response;
  }

  private async requestJson<TResponse>(url: string, data?: any, authorize: boolean = true, init?: RequestInit): Promise<TResponse> {
    const response = await this.request(url, data, authorize, "application/json", init);

    if (response.status === 204) {
      return null;
    }

    try {
      return await response.json();
    } catch {
      throw {status: response.status};
    }
  }

  private async requestBlob(url: string, data?: any, authorize: boolean = true, init?: RequestInit): Promise<Blob> {
    const response = await this.request(url, data, authorize, undefined, init);
    return await response.blob();
  }

  userAuthenticate = async (username: string, password: string) => {
    const data = await this.requestJson<{
      token: string,
      refreshCode: string
    }>(`${this.url}/v1/user/authenticate`, {
      token_type: "credentials",
      token: `${username}:${password}`,
      response_type: "full"
    }, false);

    localStorage.setItem('auth.token', data.token);
    localStorage.setItem('auth.refreshCode', data.refreshCode);
  }

  codeAuthenticate = async (token: string) => {
    const data = await this.requestJson<{
      token: string,
      refreshCode: string
    }>(`${this.url}/v1/user/authenticate`, {
      token,
      token_type: "code",
      response_type: "full"
    }, false);

    localStorage.setItem('auth.token', data.token);
    localStorage.setItem('auth.refreshCode', data.refreshCode);
  }

  mansMobillyAuthenticate = async (code: string, verifier?: string, redirect_uri?: string) => {
    const body = new FormData();
    body.append('client_id', this.oauthMobillyClientId);
    body.append('code', code);
    body.append('grant_type', 'authorization_code');

    if ( verifier ) {
      body.append('code_verifier', verifier);
    }
    if ( redirect_uri ) {
      body.append('redirect_uri', redirect_uri);
    }

    const response = await fetch(`${this.myMobillyUrl}/oauth/token`, {method: 'POST', body});
    if (!response.ok) {
      throw { status: response.status }
    }
    const data = await response.json();
    localStorage.setItem('auth.token', data.id_token);
    localStorage.removeItem('auth.refreshCode');
  }

  logout = () => {
    localStorage.removeItem('auth.token');
    localStorage.removeItem('auth.refreshCode');
  }

  isLoggedOn = () => {
    return localStorage.getItem('auth.token') !== null;
  }

  getPermissions = (params: {
    name: string,
  }[]) => this.requestJson<IPermissionsData[]>(`${this.url}/v1/user/permissions`, params);


  getParkingLotList = (params: {
    zone_id?: number
    plate_number?: string
    occurred_from?: string
    occurred_till?: string
    state?: string
    limit?: number
    page?: number
  }) => this.requestJson<{
    modify?: true
    events: IParkingLotListEventData[]
    totalEvents: number
  }>(`${this.url}/v1/parking_lot/list`, params);

  exportParkingLotList = (params: {
    zone_id?: number
    plate_number?: string
    occurred_from?: string
    occurred_till?: string
    state?: string
  }) => this.requestBlob(`${this.url}/v1/parking_lot/export`, params);

  getParkingLotListZones = () => this.requestJson<{
    zones: IParkingLotListZoneData[]
  }>(`${this.url}/v1/parking_lot/list_zones`);

  getParkingLotAddZones = () => this.requestJson<{
    zones: IParkingLotAddZoneData[]
  }>(`${this.url}/v1/parking_lot/create_zones`);

  createParkingLotSession = (params: {
    zone_id: number
    plate_number: string
    entry_at: string
    entry_camera_id: number
    exit_at?: string
    exit_camera_id?: number
    start_autopay?: boolean
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/create`, params);

  startParkingLotSession = (params: {
    event_id: number
    exit_event_id?: number
    exit_at?: string
    exit_camera_id?: number
    start_autopay?: boolean
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/start`, params);

  getParkingLotSessionSpecialRates = (params: {
    session_id: number
  }) => this.requestJson<{
    special_rates: IParkingLotSessionSpecialRatesRateData[]
  }>(`${this.url}/v1/parking_lot/special_rates`, params);

  getParkingLotSessionPayments = (params: {
    session_id: number
  }) => this.requestJson<{
    modify?: boolean
    payments: IParkingLotSessionPaymentsPaymentData[]
  }>(`${this.url}/v1/parking_lot/payments`, params);

  assignParkingLotSessionSpecialRate = (params: {
    session_id: number
    offer_id: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/assign_special_rate`, params);

  cancelParkingLotSessionSpecialRate = (params: {
    session_id: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/cancel_special_rate`, params)

  recalculateParkingLotSessionAmounts = (params: {
    session_id: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/recalculate_amounts`, params);

  getParkingLotSessionAttachablePayments = (params: {
    session_id: number
  }) => this.requestJson<{
    payments: IParkingLotSessionAttachablePaymentsPaymentData[]
  }>(`${this.url}/v1/parking_lot/attachable_payments`, params);

  attachParkingLotSessionPayment = (params: {
    session_id: number
    payment_id: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/attach_payment`, params);

  refundParkingLotSessionPayment = (params: {
    session_id: number
    payment_id: number
    amount: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/refund_payment`, params);

  detachParkingLotSessionPayment = (params: {
    session_id: number
    payment_id: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/detach_payment`, params);

  startParkingLotAutopay = (params: {
    session_id: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/start_autopay`, params);

  getParkingLotStopEvents = (params: {
    zone_id: number
    plate_number: string
    occurred_after: string
  }) => this.requestJson<{
    events: IParkingLotStopEventsEventData[]
  }>(`${this.url}/v1/parking_lot/stop_events`, params);

  stopParkingLotSession = (params: {
    session_id: number
    event_id: null | number
  } | {
    session_id: number
    exit_at: string
    exit_camera_id: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/stop`, params);

  cancelParkingLotSession = (params: {
    session_id: number
    archive?: boolean
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/cancel`, params);

  updateParkingLotEvent = (params: {
    event_id: number
    plate_number?: string
    type?: 'entry' | 'exit'
    archive?: boolean
    add_ignore?: boolean
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/update`, params);

  updateParkingLotSessionPlateNumber = (params: {
    session_id: number
    plate_number: string
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/update_plate_number`, params);

  updateParkingLotSessionTotalAmount = (params: {
    session_id: number
    amount: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/update_total_amount`, params);

  massUpdateParkingLotEventArchive = (params: {
    event_ids: number[]
    value: boolean
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_lot/mass_update_archived`, params);


  getParkingPaymentsList = (params: {
    zone_id?: number
    plate_number?: string
    paid_from?: string
    paid_till?: string
    state?: string
    limit?: number
    page?: number
  }) => this.requestJson<{
    modify?: boolean
    payments: IParkingPaymentsListData[]
    totalPayments: number
  }>(`${this.url}/v1/parking_payments/list`, params);

  exportParkingPaymentsList = (params: {
    zone_id?: number
    plate_number?: string
    paid_from?: string
    paid_till?: string
    state?: string
  }) => this.requestBlob(`${this.url}/v1/parking_payments/export`, params);

  getParkingPaymentsListZones = () => this.requestJson<{
    zones: IParkingPaymentsListZoneData[]
  }>(`${this.url}/v1/parking_payments/list_zones`);

  updateParkingPaymentPlateNumber = (params: {
    external_payment_id: number
    plate_number: string
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_payments/update_plate_number`, params);

  refundParkingPayment = (params: {
    payment_id: number
    amount: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/parking_payments/refund`, params);


  getSubscriptionsList = (params: {
    zone_id?: number
    plate_number?: string
    subscription_type_id?: number
    client?: string
    purchased_from?: string
    purchased_till?: string
    notes?: string
    limit?: number
    page?: number
  }) => this.requestJson<{
    modify?: boolean
    subscriptions: ISubscriptionsListData[]
    totalSubscriptions: number
  }>(`${this.url}/v1/subscriptions/list`, params);

  exportSubscriptionsList = (params: {
    zone_id?: number
    plate_number?: string
    subscription_type_id?: number
    client?: string
    purchased_from?: string
    purchased_till?: string
    notes?: string
  }) => this.requestBlob(`${this.url}/v1/subscriptions/export`, params);

  getSubscriptionsListZones = () => this.requestJson<{
    zones: ISubscriptionsListZoneData[]
  }>(`${this.url}/v1/subscriptions/list_zones`);

  getSubscriptionsAddZones = () => this.requestJson<{
    zones: ISubscriptionsAddZoneData[]
  }>(`${this.url}/v1/subscriptions/create_zones`);

  createSubscription = (params: {
    offer_id: number
    start: string
    till?: string
    count: number
    single?: boolean
    plate_numbers?: string[]
    client?: string
    notes?: string
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/subscriptions/create`, params);

  updateSubscription = (params: {
    subscription_id: number
    from?: string
    till?: string | null
    plate_numbers?: string[]
    notes?: string
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/subscriptions/update`, params);

  stopSubscription = (params: {
    subscription_id: number,
  }) => this.requestJson<{
    status: boolean,
  }>(`${this.url}/v1/subscriptions/stop`, params);

  deleteSubscription = (params: {
    subscription_id: number
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/subscriptions/delete`, params);


  getPermitsList = (params: {
    zone_id?: number
    plate_number?: string
    client?: string
    assigned_from?: string
    assigned_till?: string
    notes?: string
    limit?: number
    page?: number
  }) => this.requestJson<{
    modify?: boolean
    permits: IPermitsListData[]
    totalPermits: number
  }>(`${this.url}/v1/permits/list`, params);

  exportPermitsList = (params: {
    zone_id?: number
    plate_number?: string
    client?: string
    assigned_from?: string
    assigned_till?: string
    notes?: string
  }) => this.requestBlob(`${this.url}/v1/permits/export`, params);

  getPermitsListZones = () => this.requestJson<{
    zones: IPermitsListZoneData[]
  }>(`${this.url}/v1/permits/list_zones`);

  getPermitsAddZones = () => this.requestJson<{
    zones: IPermitsAddZoneData[]
  }>(`${this.url}/v1/permits/create_zones`);

  createPermit = (params: {
    zone_group_id: number
    from: string
    till?: string
    count: number
    plate_numbers?: string[]
    client?: string
    notes?: string
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/permits/create`, params);

  updatePermit = (params: {
    permit_id: number
    till?: string | null
    count?: number
    plate_numbers?: string[]
    notes?: string
  }) => this.requestJson<{
    status: boolean
  }>(`${this.url}/v1/permits/update`, params);

  stopPermit = (params: {
    permit_id: number,
  }) => this.requestJson<{
    status: boolean,
  }>(`${this.url}/v1/permits/stop`, params);

  deletePermit = (params: {
    permit_id: number,
  }) => this.requestJson<{
    status: boolean,
  }>(`${this.url}/v1/permits/delete`, params);


  getBarriersList = () =>
    this.requestJson<{
      barriers: {
        id: number,
        code: string,
      }[]
    }>(`${this.url}/v1/barriers/list`);


  openBarrier = (id: number) =>
    this.requestJson<{
      status: boolean,
    }>(`${this.url}/v1/barriers/open`,{id});

  getSpecialRatesList = () =>
    this.requestJson<ISpecialRatesListData>(`${this.url}/v1/special_rates/sessions`);

  getSpecialRatesZones = () =>
    this.requestJson<ISpecialRatesZonesData>(`${this.url}/v1/special_rates/zones`);

  getSpecialRatesPlateNumbers = (params: {
    zone_id: number
    plate_number: string
  }) =>
    this.requestJson<ISpecialRatesPlateNumbersData>(`${this.url}/v1/special_rates/plate_numbers`, params);

  assignSpecialRate = (params: {
    plate_number: string
    offer_id: number
  }) =>
    this.requestJson<{
      status: boolean
    }>(`${this.url}/v1/special_rates/assign`, params);

  getActiveSubscriptions = async (): Promise<{
    subscriptions: ActiveSubscription[]
    successful: boolean
    message?: string
  }> => {
    try {
      const response = await this.requestJson<{
        subscriptions: any[]
      }>(`${this.url}/v1/user/subscriptions/list`);
      let subscriptions = [],
        successful = true;
      try {
        subscriptions = response.subscriptions.map(s => new ActiveSubscription(s));
      } catch (e) {
        successful = false
      }
      return { subscriptions, successful }
    } catch (e) {
      return { successful: false, subscriptions: [], message: e.message }
    }
  }

  getOffers = async (): Promise<{
    successful: boolean
    zones: ZoneOfferModel[]
    cars: Car[]
  }> => {
    try {
      const response = await this.requestJson<{
        zones: []
        cars: []
      }>(`${this.url}/v1/user/subscriptions/offers`);
      let zones = [],
        cars = [],
        successful = true
      try {
        zones = response.zones.map(z => new ZoneOfferModel(z))
        cars = response.cars.map(c => new Car({ plateNumber: c }))
      } catch (e) {
        successful = false
      }
      return { successful, zones, cars }
    } catch (e) {
      return { successful: false, zones: [], cars: [] }
    }
  }

  makePurchase = async (params: {
    offer_id: number
    count: number
    start: Date
    plates: string[]
    auto_renew: boolean
  }, language: string): Promise<{
    successful: boolean
    complete?: boolean
    redirectUrl?: string
    code?: number
    message?: string
    extra?: any
  }> => {

    try {
      const { payment_request_id } = await this.requestJson<{
        amount: number
        currency: string
        payment_request_id: number
      }>(`${this.url}/v1/payment/subscription/request`, {
        ...params,
        start: new Date(params.start).toISOString(),
      });

      if (!payment_request_id) {
        return {
          successful: false,
          message: 'Kļūda apstrādājot servera atbildi',
        }
      }

      const { redirect_to, complete } = await this.requestJson<{
        redirect_to: string
        complete: boolean
      }>(`${this.mpayAdapter}/v1/start`, {
        payment_request_id,
        redirect_url: `${location.origin}/subscriptions/home?lang=${language}`,
        language_code: language,
      });

      return {
        successful: true,
        redirectUrl: redirect_to,
        complete,
      }
    } catch (e) {
      return {
        successful: false,
        code: e.code,
        message: e.message || 'Neizdevās izveidot savienojumu ar serveri',
        extra: e.extra,
      }
    }
  }

  modify = async (params: {
    subscription_id: number
    plates?: string[]
    notes?: string
  }): Promise<{
    successful: boolean
    code?: number
    message?: string
    extra?: any
  }> => {
    try {
      await this.requestJson<boolean>(`${this.url}/v1/user/subscriptions/modify`, params);

      return {
        successful: true,
      }
    } catch (e) {
      return {
        successful: false,
        code: e.code,
        message: e.message,
        extra: e.extra,
      }
    }
  }
}

const Context = React.createContext<Api | null>(null)

export const useApi = () => {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error('useApi must be used within a ApiProvider')
  }
  return context
}

export function ApiProvider({children}: { children: React.ReactNode }): JSX.Element {
  const config = useConfig()

  return (
    <Context.Provider
      value={new Api(config.uri, config.myMobillyUri, config.mpayAdapter, config.oauthMobillyClientId)}
    >
      {children}
    </Context.Provider>
  )
}
