import React, { FunctionComponent } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useApi } from 'components/providers/ApiProvider'
import { useMessage } from 'components/elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import { useSubscriptions } from 'components/views/subscriptions/subscriptions/SubscriptionsProvider'
import { TextField } from 'components/elements/TextField'
import { formatDateFieldToISO, formatToDateField, parseDateField, yupDateField } from 'utils'
import { DateField } from 'Elements/DateField'

export interface EditSubscriptionDialogData {
  id: number
  validFrom: string
  validTill: string
  plateNumbers: string[]
  clients: string[]
  notes: string
  updatable: string[]
}

type Props = {
  open: boolean
  data: EditSubscriptionDialogData
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const EditSubscriptionDialog: FunctionComponent<AllProps> = ({ open, setOpen, data }) => {
  if (!data) {
    return null
  }

  const { t } = useTranslation()
  const api = useApi()
  const { setLoadList } = useSubscriptions()
  const { showSuccess, showError } = useMessage()

  const updateValidPeriod = data.updatable.includes('valid_period')
  const updateClients = data.updatable.includes('clients')

  const handleEnter = () => {
    if (updateValidPeriod) {
      formal.change('validFrom', formatToDateField(new Date(data.validFrom)))
      if (data.validTill) {
        formal.change('validTill', formatToDateField(new Date(data.validTill)))
      }
    }
    formal.change('plateNumbers', Array.from(data.plateNumbers).sort().join(', '))
    if (updateClients) {
      formal.change('clients', Array.from(data.clients).join(', '))
    }
    formal.change('notes', data.notes)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExited = () => {
    formal.change('validFrom', initialValues.validFrom)
    formal.change('validTill', initialValues.validTill)
    formal.change('plateNumbers', initialValues.plateNumbers)
    formal.change('clients', initialValues.clients)
    formal.change('notes', initialValues.notes)
    formal.clearErrors()
  }

  const initialValues = {
    validFrom: '',
    validTill: '',
    plateNumbers: '',
    clients: '',
    notes: '',
  }

  const schema = yup.object().shape({
    validFrom: yupDateField({ required: updateValidPeriod }),
    validTill: yupDateField(),
    plateNumbers: yup.string().matches(/^[0-9a-zA-Z, \n]*$/),
    clients: yup.string().trim().matches(/^[0-9a-zA-Z, ]+$/, { excludeEmptyString: true }),
    notes: yup.string().trim(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: async values => {
      values = schema.cast(values)

      await api.updateSubscription({
        subscription_id: data.id,
        ...(updateValidPeriod && {
          from: formatDateFieldToISO(values.validFrom),
          till: values.validTill ? formatDateFieldToISO(values.validTill) : null,
        }),
        plate_numbers: values.plateNumbers ? values.plateNumbers.split(/[,\n]/).map(plateNumber => plateNumber.replace(/\s/g, '').toUpperCase()).filter((plateNumber, index, self) => plateNumber && self.indexOf(plateNumber) === index) : [],
        ...(updateClients && {
          clients: values.clients ? values.clients.split(/[,]/).map(client => client.trim()).filter((client, index, self) => client && self.indexOf(client) === index) : [],
        }),
        notes: values.notes,
      })
        .then(data => {
          if (!data || !data.status) {
            throw new Error
          }

          handleClose()

          setLoadList()

          showSuccess(t('messages.subscriptionUpdated'))
        })
        .catch(error => {
          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            case 6:
              message = t('alerts.maxSubscriptionsReached')
              break
            case 16:
              message = t('alerts.subscriptionFromDateAfterFirstSessionDay', { date: formatToDateField(new Date(error.extra.firstSessionDay)) })
              break
            case 9:
              message = t('alerts.maxSubscriptionVehiclesReached')
              break
            case 10:
              message = t('alerts.subscriptionVehicleOverlap', { plates: error.extra.plates.join(', ') })
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotUpdateSubscription')} ${message}`)
        })
    },
  })

  return (
    <DraggableDialog fullWidth open={open} onEnter={handleEnter} onClose={handleClose} onExited={handleExited}>
      <DraggableDialogTitle>{t('editSubscription')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container spacing={2}>
            {updateValidPeriod && <>
              <DateField
                GridProps={{ xs: 6 }}
                label={t('startDate')}
                required
                autoFocus
                {...(formal.values.validTill && { maxDate: parseDateField(formal.values.validTill) })}
                {...formal.getFieldProps('validFrom')}
              />
              <DateField
                GridProps={{ xs: 6 }}
                label={t('endDate')}
                minDate={parseDateField(formal.values.validFrom)}
                clearable
                {...formal.getFieldProps('validTill')}
              />
            </>}
            <TextField
              GridProps={{ xs: 12 }}
              label={t('plateNumbers')}
              multiline
              autoComplete="off"
              helperText={`${t('example')}: AA0001, AA0002`}
              inputProps={{
                style: {
                  fontFamily: '"Roboto Mono", monospace',
                  lineHeight: '21px',
                  textTransform: 'uppercase',
                  marginTop: -1,
                  marginBottom: -1,
                  maxHeight: 63,
                  overflow: 'auto',
                },
              }}
              {...(!updateValidPeriod && { autoFocus: true })}
              {...formal.getFieldProps('plateNumbers')}
            />
            {updateClients && <TextField
              GridProps={{ xs: 12 }}
              label={t('client')}
              helperText={t('clientPhoneNumberOrUsernameHelperText')}
              {...formal.getFieldProps('clients')}
            />}
            <TextField
              GridProps={{ xs: 12 }}
              label={t('notes')}
              {...formal.getFieldProps('notes')}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formal.isSubmitting}
          >
            {t('edit')}
          </Button>
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default EditSubscriptionDialog
