import React, { FunctionComponent, useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableBody from '@material-ui/core/TableBody'
import { useSubscriptions } from 'components/views/subscriptions/subscriptions/SubscriptionsProvider'
import { formatMoney, formatDateTimeFieldToISO, formatTimestamp } from 'utils'
import { useTranslation } from 'react-i18next'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import IconEdit from '@material-ui/icons/Edit'
import IconStop from '@material-ui/icons/Stop'
import IconDelete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import IconInfo from '@material-ui/icons/InfoOutlined'
import { ISubscriptionsListData, useApi } from 'components/providers/ApiProvider'
import { useHistory } from 'react-router-dom'
import { useMessage } from 'components/elements/Message'
import { Skeleton } from '@material-ui/lab'
import Tooltip from '@material-ui/core/Tooltip'
import AutoRenewIcon from '@material-ui/icons/Autorenew'
import EditSubscriptionDialog, { EditSubscriptionDialogData } from 'components/views/subscriptions/subscriptions/partials/EditSubscriptionDialog'
import { Container, LinearProgress } from '@material-ui/core'
import { TablePaginationActions } from 'components/elements/TablePaginationActions'
import Alert from '@material-ui/lab/Alert'

const useClasses = makeStyles(theme => ({
  fadeTableRow: {
    '& td': {
      color: theme.palette.grey['A200'],
    },
  },
}))

const NotesTableCell = withStyles(theme => ({
  root: {
    color: theme.palette.text.disabled,
    fontStyle: 'italic',
    maxWidth: 200,
  },
}))(TableCell)

type Props = {}
type Functions = {}
type AllProps = Props & Functions

const List: FunctionComponent<AllProps> = ({}) => {
  const { t } = useTranslation()
  const api = useApi()
  const history = useHistory()
  const { filter, loadList, listLoading, setListLoading, listPerPage, setLoadChangePerPageList, listPage, setListPage, setLoadChangePageList } = useSubscriptions()
  const { fadeTableRow } = useClasses()
  const { showSuccess, showError } = useMessage()

  const [modify, setModify] = useState<boolean>(false)
  const [subscriptions, setSubscriptions] = useState<ISubscriptionsListData[]>(null)
  const [totalSubscriptions, setTotalSubscriptions] = useState(0)

  const [openEditSubscriptionDialog, setOpenEditSubscriptionDialog] = useState(false)
  const [editSubscriptionDialogData, setEditSubscriptionDialogData] = useState<EditSubscriptionDialogData>(null)

  const load = (onLoad?: Function, page?: number) => {
    if (!page) {
      setListLoading(true)
    }

    api.getSubscriptionsList({
      zone_id: filter.zone || undefined,
      plate_number: filter.plateNumber.toUpperCase() || undefined,
      subscription_type_id: filter.subscriptionType || undefined,
      client: filter.client || undefined,
      ...(filter.purchasedFrom && { purchased_from: formatDateTimeFieldToISO(filter.purchasedFrom) }),
      ...(filter.purchasedTill && { purchased_till: formatDateTimeFieldToISO(filter.purchasedTill) }),
      notes: filter.notes || undefined,
      limit: listPerPage,
      page: page || listPage,
    })
      .then(data => {
        if (!page && data.subscriptions && !data.subscriptions.length && data.totalSubscriptions) {
          const pages = Math.ceil(data.totalSubscriptions / listPerPage)

          if (listPage > pages) {
            setListPage(pages)
            load(onLoad, pages)

            return
          }
        }

        setListLoading(false)

        if (onLoad) {
          onLoad(data.subscriptions)
        }

        setModify(Boolean(data.modify))
        setSubscriptions(data.subscriptions)
        setTotalSubscriptions(data.totalSubscriptions)
      })
      .catch(error => {
        setListLoading(false)

        if (error.status == 401) {
          history.push('/')

          return
        }

        showError(`${t('alerts.couldNotLoadData')} ${t('alerts.tryLater')}`)
      })
  }

  useEffect(() => {
    load()
  }, [loadList])

  const handleEdit = (subscription: ISubscriptionsListData) => {
    const { id, valid_from: validFrom, valid_till: validTill, plate_numbers: plateNumbers, openid_clients: clients, notes, modify } = subscription

    setEditSubscriptionDialogData({
      id,
      validFrom,
      validTill,
      plateNumbers,
      clients,
      notes,
      updatable: modify.updatable,
    })
    setOpenEditSubscriptionDialog(true)
  }

  const handleStop = (subscription: ISubscriptionsListData) => {
    if (!confirm((subscription.auto_renew ? t('confirms.subscriptionAutoRenewNotice') + ' ' : '') + t('confirms.stopSubscription'))) {
      return
    }

    api.stopSubscription({
      subscription_id: subscription.id,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load()

        showSuccess(t('messages.subscriptionStopped'))
      })
      .catch(error => {
        let message

        switch (error.code) {
          default:
            message = t('alerts.tryLater')
        }

        showError(`${t('alerts.couldNotStopSubscription')} ${message}`)
      })
  }

  const handleDelete = (subscription: ISubscriptionsListData) => {
    if (!confirm((subscription.auto_renew ? t('confirms.subscriptionAutoRenewNotice') + ' ' : '') + t('confirms.deleteSubscription'))) {
      return
    }

    api.deleteSubscription({
      subscription_id: subscription.id,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load()

        showSuccess(t('messages.subscriptionDeleted'))
      })
      .catch(error => {
        let message

        switch (error.code) {
          default:
            message = t('alerts.tryLater')
        }

        showError(`${t('alerts.couldNotDeleteSubscription')} ${message}`)
      })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setLoadChangePageList(page + 1)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLoadChangePerPageList(parseInt(event.target.value, 10))
  }

  const nowTime = (new Date).getTime()

  return (
    <>
      {subscriptions && listLoading && (
        <LinearProgress style={{ height: 1, marginBottom: -1 }} />
      )}

      {subscriptions && !subscriptions.length && (
        <Container maxWidth="md" style={{ paddingTop: 36 }}>
          <Alert severity="info">{t('noRecords')}</Alert>
        </Container>
      )}

      {(!subscriptions || Boolean(subscriptions.length)) && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                {!subscriptions && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {subscriptions && (
                  <TableRow>
                    <TableCell>{t('zone')}</TableCell>
                    <TableCell>{t('purchasedAt')}</TableCell>
                    <TableCell>{t('type')}</TableCell>
                    <TableCell>{t('period')}</TableCell>
                    <TableCell>{t('quantity')}</TableCell>
                    <TableCell>{t('plateNumbers')}</TableCell>
                    <TableCell>{t('client')}</TableCell>
                    <TableCell>{t('paidIncVAT')}</TableCell>
                    <TableCell>{t('notes')}</TableCell>
                    {modify && <TableCell colSpan={4} />}
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {!subscriptions && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                      <Skeleton style={{ margin: '8px 0' }} />
                      <Skeleton />
                      <Skeleton style={{ margin: '8px 0' }} />
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {subscriptions && subscriptions
                  .map((subscription, key) => {
                    const closedSubscription = subscription.valid_till && new Date(subscription.valid_till).getTime() <= nowTime

                    return (<TableRow key={key} hover {...(closedSubscription && { className: fadeTableRow })}>
                      <TableCell>
                        {subscription.zone}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {formatTimestamp(subscription.purchased_at)}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        <span dangerouslySetInnerHTML={{__html: subscription.subscription_type.trim().replace(/\s+/g, '<span style="white-space: normal"> </span>')}}></span>
                        {subscription.auto_renew && (
                          <Tooltip title={t('subscriptionPurchase.autoRenew')} placement={'top'} arrow={true}>
                            <AutoRenewIcon fontSize="small" color="primary" style={{ verticalAlign: 'top', marginLeft: 2 }} />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        {formatTimestamp(subscription.valid_from, 'dd.MM.yyyy')} - {subscription.valid_till ? formatTimestamp(subscription.valid_till, 'dd.MM.yyyy') : '...'}
                      </TableCell>
                      <TableCell>
                        {subscription.count}
                      </TableCell>
                      <TableCell style={{ fontFamily: '"Roboto Mono", monospace', maxWidth: 400 }}>
                        {Array.from(subscription.plate_numbers).sort().join(', ')}
                      </TableCell>
                      <TableCell>
                        {subscription.clients.join(', ')}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {formatMoney(subscription.price)}
                      </TableCell>
                      <NotesTableCell>
                        {subscription.notes}
                      </NotesTableCell>
                      {modify && (
                        <>
                          <TableCell align="center" style={{ width: 62 }}>
                            <Tooltip title={`#: ${subscription.id}`} placement={'top'} arrow interactive>
                              <IconInfo fontSize="small" style={{ verticalAlign: 'middle' }} />
                            </Tooltip>
                          </TableCell>
                          <TableCell style={{ paddingLeft: 0, width: 46 }}>
                            {Boolean(subscription.modify.updatable.length) && (
                              <IconButton
                                color="secondary"
                                size="small"
                                style={{ margin: '-5px 0' }}
                                onClick={() => handleEdit(subscription)}
                              >
                                <IconEdit />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell style={{ paddingLeft: 0, width: 46 }}>
                            {subscription.modify.stoppable && (
                              <IconButton
                                color="secondary"
                                size="small"
                                style={{ margin: '-5px 0' }}
                                onClick={() => handleStop(subscription)}
                              >
                                <IconStop />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell style={{ paddingLeft: 0, width: 46 }}>
                            {subscription.modify.deletable && (
                              <IconButton
                                color="secondary"
                                size="small"
                                style={{ margin: '-5px 0' }}
                                onClick={() => handleDelete(subscription)}
                              >
                                <IconDelete />
                              </IconButton>
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>)
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalSubscriptions}
            rowsPerPage={listPerPage}
            page={listPage - 1}
            labelRowsPerPage={`${t('show')}:`}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} / ${count !== -1 ? count : '...'}`}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </>
      )}
      {modify && <EditSubscriptionDialog open={openEditSubscriptionDialog} setOpen={setOpenEditSubscriptionDialog} data={editSubscriptionDialogData} />}
    </>
  )
}

export default List
